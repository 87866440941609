import React from "react";
import logo from "../../assets/images/ScoreLeapLogoWhite.png";
import { Layout } from "antd";

const TopBar = Layout.Header;

const Header = () => {
  return (
    <div>
      <TopBar style={{ backgroundColor: "#15496F" }} className="px-0">
        <div className="container mx-auto px-4 py-2 text-center md:text-left">
          <div className="inline-block">
            <img src={logo} alt="Brand Logo"></img>
          </div>
        </div>
      </TopBar>
    </div>
  );
};

export default Header;
