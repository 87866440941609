import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Form, Input, Button } from "antd";

const SignUpPage = () => {
  return (
    <div className="container mx-auto px-4 py-2">
      <div className="text-center text-4xl font-semibold mt-3">
        Create Your Account
      </div>
      <div className="font-serif md:w-3/5 mt-2 mx-auto">
        <p className="font-bold">
          Already Have an account? <Link to={ROUTES.SIGN_IN}>Sign in here</Link>
        </p>
        <p className="mt-3">
          Congratulations on taking your first step toward accomplishing your
          educational goals! Creating an account on ScoreLeap.in will give you
          resources to take control of your graduate business degree journey and
          guide you through the steps needed to get into the best program for
          you.
        </p>
        <p className="mt-3">With your account, you can:</p>
        <ul className="list-disc list-inside mt-3">
          <li>
            Access multiple resources that could be helpful for Standardised
            test and your education aboard.
          </li>
          <li>
            You will have accesses to some of Strategy Sessions that will help
            you increase your Test Scores
          </li>
          <li>
            Download multiple Official Guides with solutions sets for your
            practice
          </li>
          <li>Access the School Rankings as per the Latest US news Rankings</li>
        </ul>
        <p className="font-bold mt-5">
          Stay informed with the latest news, updates and offers.
        </p>
        <p className="mt-3">
          Please send me the latest information about management education,
          tests, events, products, and other offers from ScoreLeap. Update your
          preferences, unsubscribe, or contact ScoreLeap any time.
        </p>
        <p className="font-bold mt-5">Terms of Use</p>
        <p className="mt-3">
          By clicking below, I agree to the ScoreLeap 
          <a
            href="https://www.scoreleap.in/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#15496F" }}
          >
            Privacy Policy.
          </a>
        </p>
        <p className="mt-3">
          I acknowledge that I may receive email communications from ScoreLeap
          related to products or services that I use and invitations to
          participate in Scoreleap survey research.
        </p>
      </div>
      <SignUpForm />
    </div>
  );
};

const SignUpForm = () => {
  return (
    <Form className="md:w-3/5 mt-5 mx-auto" initialValues={{ remember: true }}>
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: "Please input your First Name!" }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: "Please input your Last Name!" }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input type="email" placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>

      <Form.Item>
        <Button className="rounded-full" type="primary" htmlType="submit">
          Create Account
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignUpPage;
