import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import * as ROUTES from "../../constants/routes";

const SignIn = () => {
  return (
    <div className="container mx-auto px-4 py-2">
      <div className="text-center text-4xl font-semibold mt-3">
        Log in to Your Account
      </div>
      <div className="font-serif md:w-3/5 mt-2 mx-auto"></div>
      <SignInForm />
    </div>
  );
};

const SignInForm = () => {
  return (
    <Form className="md:w-3/5 mt-5 mx-auto" initialValues={{ remember: true }}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input type="email" placeholder="Email Address" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <div>
        <Link className="font-bold" to={ROUTES.PASSWORD_FORGET}>
          Forgot Password?
        </Link>
      </div>
      <div className="mt-2">
        <Link className="font-bold" to={ROUTES.SIGN_UP}>
          Need an Account?
        </Link>
      </div>

      <Form.Item className="mt-4">
        <Button className="rounded-full" type="primary" htmlType="submit">
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignIn;
