import React from "react";

const Landing = () => {
  return (
    <>
      <h1 className="font-sans">Hello World</h1>
      <h2>Hello</h2>
      <div className="font-serif">Hello World</div>
    </>
  );
};

export default Landing;
