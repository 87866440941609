import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../Header/index";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import GmatPage from "../Gmat";
import GrePage from "../Gre";
import SatPage from "../Sat";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import PasswordForgetPage from "../PasswordForget";

import * as ROUTES from "../../constants/routes";

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.GMAT} component={GmatPage} />
        <Route path={ROUTES.GRE} component={GrePage} />
        <Route path={ROUTES.SAT} component={SatPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      </Switch>
    </Router>
  );
};
export default App;
